<template>
  <div class="delivery-message">
    <div class="delivery-message__header">{{ header }}</div>
    <div class="delivery-message__time">{{ time }}</div>
    <div class="delivery-message__date">{{ formattedDate }}</div>
    <div v-if="note.length > 0" class="delivery-message__note">
      {{ note }}
    </div>
    <div v-if="downloadLabelsEnabled" class="delivery-message__actions">
      <btn label="Hent PDF-labels" @click="$emit('download-labels-click')" />
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { da } from "date-fns/locale";

import Btn from "@/components/Btn";

export default {
  name: "DeliveryMessage",
  components: { Btn },
  props: {
    header: {
      type: String,
      default: "",
    },
    time: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    note: {
      type: String,
      default: "",
    },
    downloadLabelsEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedDate() {
      if (!this.date) return "";

      return format(new Date(this.date), "d. MMMMMM yyyy", { locale: da });
    },
  },
};
</script>

<style>
.delivery-message {
  padding: 10px 0;
}
.delivery-message__header {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}
.delivery-message__time {
  font-size: 32px;
  font-weight: bold;
  color: #05bce4;
}
.delivery-message__date {
  font-size: 20px;
  font-weight: bold;
}
.delivery-message__note {
  margin-top: 20px;
  color: #00d259;
}
.delivery-message__actions {
  margin-top: 40px;
}

@media screen and (min-width: 768px) {
  .delivery-message {
    padding-left: 30px;
  }
}
</style>
