<template>
  <div class="overview">
    <div class="overview-content">
      <row
        ><overview-table-bar>
          <date-picker
            v-model="date"
            range
            placeholder="Vælg start- og slutdato"
          ></date-picker>
          <overview-table-search @search="search" />
        </overview-table-bar>
      </row>
      <row scroll>
        <loading-indicator v-if="!overviewStore.loaded" />
        <overview-table-headline
          v-if="overviewStore.loaded"
          :total="pagination.total"
          :from="this.date[0]"
          :to="this.date[1]"
        />
        <overview-table
          v-if="overviewStore.loaded && pagination.total > 0"
          :shipments="overview"
          @shipment-click="shipmentClick"
        />
        <pagination
          v-if="overviewStore.loaded && pagination.total > 0"
          :pages="pagesNum"
          :current-page="pagination.currentPage"
          @page-change="pageChange"
        />
        <no-results
          v-if="overviewStore.loaded && pagination.total === 0"
          message="Ingen forsendelser"
        />
      </row>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useOverviewStore } from "../store/OverviewStore";
import { useLoginStore } from "../store/LoginStore";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/da";
import { format } from "date-fns";
import { da } from "date-fns/locale";

import LoadingIndicator from "@/components/LoadingIndicator";
import Row from "@/components/Row";
import OverviewTableBar from "@/components/OverviewTableBar";
import OverviewTableHeadline from "@/components/OverviewTableHeadline";
import OverviewTableSearch from "@/components/OverviewTableSearch";
import OverviewTable from "@/components/OverviewTable";
import NoResults from "@/components/NoResults";
import Pagination from "@/components/Pagination";

export default {
  name: "Overview",
  components: {
    DatePicker,
    LoadingIndicator,
    Row,
    OverviewTableBar,
    OverviewTableHeadline,
    OverviewTableSearch,
    OverviewTable,
    NoResults,
    Pagination,
  },
  data() {
    return {
      date: null,
    };
  },
  created() {
    this.overviewStore.loaded = false;
  },
  mounted() {
    // Redirect to login screen if user is not logged in
    if (!this.loginStore.loggedIn) {
      this.$router.replace({ name: "Login" });
      return;
    }

    if (this.$route.query.from && this.$route.query.to) {
      // If a date query is present in the URL, set it ans perform a search
      this.date = [
        new Date(this.$route.query.from),
        new Date(this.$route.query.to),
      ];
      this.overviewStore.search(this.loginStore.auth, this.$route.query);
    } else {
      // If no date query is present in the URL, set the default date range
      this.date = this.defaultDateRange();
    }
  },
  watch: {
    "$route.query": function (query) {
      if (query.to && query.from) {
        // Only search with a to and from date
        this.overviewStore.search(this.loginStore.auth, query);
      }
    },
    date(date) {
      let query = {};
      if (date[0] !== null && date[1] !== null) {
        // Set the date range in the query if present
        const from = format(new Date(date[0]), "yyyy-MM-dd", { locale: da });
        const to = format(new Date(date[1]), "yyyy-MM-dd", { locale: da });
        query = Object.assign({}, this.$route.query, { from, to });
        //FIXME: Page is not reset properly
        // delete query.page;
      } else {
        // Set the default date range in the query
        this.date = this.defaultDateRange();
      }

      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        // Replace the query in the router with the new query
        this.$router.replace({
          query: query,
        });
      }
    },
  },
  computed: {
    ...mapStores(useLoginStore, useOverviewStore),
    overview() {
      return this.overviewStore.overview.orders;
    },
    pagination() {
      return this.overviewStore.overview.pagination;
    },
    pagesNum() {
      return Math.ceil(
        this.overviewStore.overview.pagination.total /
          this.overviewStore.overview.pagination.perPage
      );
    },
  },
  methods: {
    search(term) {
      const query = Object.assign({}, this.$route.query, { searchText: term });

      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        this.$router.replace({
          query: query,
        });
      }
    },
    pageChange(page) {
      const query = Object.assign({}, this.$route.query, { page: page });
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        this.$router.replace({
          query: query,
        });
      }
    },
    shipmentClick(id) {
      this.$router.push(`/trackadvanced/${id}`);
    },
    defaultDateRange() {
      const today = new Date();
      const oneMonthAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      );
      const twoWeeksFromNow = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 14
      );
      return [oneMonthAgo, twoWeeksFromNow];
    },
  },
};
</script>
<style>
.mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  border-radius: 8px;
  height: 45px;
}

@media screen and (min-width: 768px) {
  .mx-datepicker-range {
    width: 320px !important;
  }
}
</style>
