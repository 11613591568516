<template>
  <div class="greeting-message">
    <div class="greeting-message__header">{{ header }}</div>
    <div class="greeting-message__message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: "GreetingMessage",
  props: {
    header: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.greeting-message {
  padding: 10px 0;
}
.greeting-message__header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 6px;
}
.greeting-message__message {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 6px;
}

@media screen and (min-width: 768px) {
  .greeting-message {
    margin-top: 30px;
    padding-left: 30px;
  }
}
</style>
