<template>
  <div id="track-input">
    <div class="track-input-panel">
      <div class="track-input-headers">
        <h4 class="track-input-headers__header">Indtast shipment id</h4>
      </div>
      <form @submit.prevent="submitForm" class="track-input-form">
        <div class="track-input-form-elements">
          <div class="track-input-form__shipmentid">
            <input
              type="text"
              name="shipmentid"
              placeholder="Shipment id"
              v-model="shipmentid"
            />
          </div>
          <br />
          <btn :disabled="!valid" label="Spor forsendelse" width="100%" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/Btn.vue";

export default {
  name: "TrackInput",
  components: { Btn },
  data() {
    return {
      shipmentid: "",
      submitted: false,
    };
  },
  watch: {
    loggedIn(newValue) {
      if (newValue) {
        // Redirect to overview if user is logged in
        this.$router.push("/overview");
      }
    },
  },
  computed: {
    valid() {
      return this.shipmentid.length > 0;
    },
  },
  methods: {
    submitForm() {
      this.$router.push({ path: `t/${this.shipmentid}` });
    },
  },
};
</script>

<style>
#track-input input[type="text"] {
  padding: 12px;
  border: none;
  border-radius: 3px;
}

.track-input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.track-input-panel {
  background-color: rgb(1, 73, 99);
  margin: 100px 0;
  padding: 30px 20px 50px 20px;
}
.track-input-headers {
  text-align: center;
  margin-bottom: 40px;
}
.track-input-headers__header {
  font-size: 24px;
  margin: 0;
}
.track-input-headers__subheader {
  font-size: 20px;
  margin: 0;
}
.track-input-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.track-input-form-elements {
  width: 100%;
  max-width: 250px;
}
.track-input-form__shipmentid {
  margin-bottom: 10px;
}
.track-input-form__shipmentid input {
  width: 100%;
}
.track-input-form__password {
  margin-bottom: 20px;
}
.track-input-form__password input {
  width: 100%;
}
</style>
