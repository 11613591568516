<template>
  <div class="track-advanced">
    <loading-indicator
      v-if="!trackAdvancedStore.loaded || !deliveryOptionsStore.loaded"
    />
    <div
      class="track-content"
      v-if="trackAdvancedStore.loaded && deliveryOptionsStore.loaded"
    >
      <row class="hide-print" background-color="#1d4962">
        <back-btn label="Tilbage til Overblik" @click="backClick" />
        <statusbar
          :items="trackAdvancedStore.statusItems"
          :current="trackAdvancedStore.shipment.deliveryStatus"
        />
      </row>
      <row>
        <columns>
          <column>
            <greeting-message
              :header="`Hej ${firstName}`"
              :message="trackAdvancedStore.shipment.currentStatusDescription"
            />
            <delivery-message
              :header="trackAdvancedStore.shipment.currentStatusHeader"
              :time="trackAdvancedStore.shipment.currentStatusTime"
              :date="trackAdvancedStore.shipment.currentStatusDate"
              :note="deliveryNote"
              :downloadLabelsEnabled="downloadLabelEnabled"
              @download-labels-click="downloadLabelsClick"
            />
          </column>
          <column>
            <recipient-card
              :order-type="trackAdvancedStore.shipment.orderType"
              :recipient="recipient"
              :placement="trackAdvancedStore.shipment.placement"
              :can-change-placement="canChangePlacement"
              @options-click="optionsClick"
            />
          </column>
        </columns>
      </row>
      <row>
        <package-list
          :packages="trackAdvancedStore.shipment.packages"
          :downloadLabelEnabled="downloadLabelEnabled"
          @location-click="locationClick"
          @photo-click="photoClick"
          @signature-click="signatureClick"
          @download-label-click="downloadLabelClick"
        />
      </row>
    </div>
    <modal-overlay
      v-if="signature.visible"
      @close="signature.visible = false"
      v-body-scroll-lock="signature.visible"
    >
      <image-list :images="signature.url" />
    </modal-overlay>
    <modal-overlay
      v-if="photo.visible"
      @close="photo.visible = false"
      v-body-scroll-lock="photo.visible"
    >
      <image-list :images="photo.url" />
    </modal-overlay>
    <modal-overlay
      v-if="map.visible"
      @close="map.visible = false"
      v-body-scroll-lock="map.visible"
      center
    >
      <div class="track-advanced-map">
        <l-map
          style="width: 100%; height: 100%"
          class="map"
          :zoom="map.zoom"
          :center="map.center"
        >
          <l-tile-layer
            :url="map.url"
            :attribution="map.attribution"
          ></l-tile-layer>
          <l-marker :lat-lng="map.markerLatLng"></l-marker>
        </l-map>
      </div>
    </modal-overlay>
    <modal-overlay
      v-if="options.visible"
      @close="options.visible = false"
      v-body-scroll-lock="options.visible"
      center
      :close-enabled="false"
    >
      <delivery-options
        :options="deliveryOptions"
        @close-click="options.visible = false"
        @confirm-option="saveDeliveryOption"
      />
    </modal-overlay>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useTrackAdvancedStore } from "../store/TrackAdvancedStore";
import { useLoginStore } from "../store/LoginStore";
import { useDeliveryOptionsStore } from "../store/DeliveryOptionsStore";
import LoadingIndicator from "@/components/LoadingIndicator";
import Row from "@/components/Row";
import Columns from "@/components/Columns";
import Column from "@/components/Column";
import BackBtn from "@/components/BackBtn";
import Statusbar from "@/components/Statusbar";
import GreetingMessage from "@/components/GreetingMessage";
import DeliveryMessage from "@/components/DeliveryMessage";
import RecipientCard from "@/components/RecipientCard";
import PackageList from "@/components/PackageList";
import ModalOverlay from "@/components/ModalOverlay";
import ImageList from "@/components/ImageList";
import DeliveryOptions from "@/components/DeliveryOptions";

const DELIVERY_NOTE_PARTNER_NAME_BLACKLIST = ["Flowr"];

export default {
  name: "TrackAdvanced",
  components: {
    LoadingIndicator,
    Row,
    Column,
    Columns,
    BackBtn,
    Statusbar,
    GreetingMessage,
    DeliveryMessage,
    RecipientCard,
    PackageList,
    ModalOverlay,
    ImageList,
    DeliveryOptions,
  },
  data() {
    return {
      signature: {
        url: [],
        visible: false,
      },
      photo: {
        url: [],
        visible: false,
      },
      map: {
        visible: false,
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 17,
        center: [51.505, -0.159],
        markerLatLng: [51.504, -0.159],
        photo: "",
      },
      options: {
        visible: false,
      },
    };
  },
  created() {
    this.trackAdvancedStore.loaded = false;
  },
  mounted() {
    if (this.loginStore.loggedIn) {
      this.deliveryOptionsStore.loadData();
      this.trackAdvancedStore.loadData(
        this.loginStore.auth,
        this.$route.params.id
      );
    } else {
      this.$router.replace({ name: "Login" });
    }
  },
  computed: {
    ...mapStores(useLoginStore, useTrackAdvancedStore, useDeliveryOptionsStore),
    deliveryOptions() {
      return this.deliveryOptionsStore.options;
    },
    shipment() {
      return this.trackAdvancedStore.shipment;
    },
    recipient() {
      return {
        orderNumber: this.shipment.orderId,
        name: this.shipment.recipient.name,
        address: this.shipment.recipient.address,
        address2: this.shipment.recipient.address2,
        postalcode: this.shipment.recipient.postalcode,
        city: this.shipment.recipient.city,
        mobilePhone: this.shipment.recipient.mobilePhone,
        date: this.shipment.deliveryDate,
        timeFrom: this.shipment.deliveryTimeFrom,
        timeTo: this.shipment.deliveryTimeTo,
        created: this.shipment.created,
        services: this.shipment.services,
        comment: this.shipment.commentDriver,
      };
    },
    downloadLabelEnabled() {
      return (
        this.trackAdvancedStore.shipment.deliveryStatus < 5 &&
        this.trackAdvancedStore.shipment.orderType === 1
      );
    },
    firstName() {
      return this.recipient.name.split(" ")[0];
    },
    canChangePlacement() {
      return (
        this.shipment.deliveryStatus < 5 && this.shipment.changeDeliveryOption
      );
    },
    deliveryNote() {
      // Set the note if status is 3 or 4 and partner name is not in the blacklist and info30min is active
      const deliveryStatusOK =
        this.shipment.deliveryStatus === 3 ||
        this.shipment.deliveryStatus === 4;
      const partnerNameOk =
        DELIVERY_NOTE_PARTNER_NAME_BLACKLIST.indexOf(
          this.shipment.partnerName
        ) === -1;
      const info30OK = this.shipment.info30min === 1;
      return deliveryStatusOK && partnerNameOk && info30OK
        ? "Ca. 30 min. før levering modtager du en SMS"
        : "";
    },
  },
  methods: {
    senderServiceClick() {
      window.open(`https://${this.trackAdvancedStore.shipment.supportUrl}`);
    },
    backClick() {
      this.$router.go(-1);
    },
    locationClick(coords) {
      this.map.center = coords;
      this.map.markerLatLng = coords;
      this.map.visible = true;
    },
    photoClick(url) {
      this.photo.url = url;
      this.photo.visible = true;
    },
    signatureClick(url) {
      this.signature.url = url;
      this.signature.visible = true;
    },
    downloadLabelClick(packageId) {
      const url = `https://api2.bftlogistik.dk:7100/pdflabel/v3/${this.shipment.userId}/${this.shipment.shipmentId}/${packageId}`;
      window.open(url);
    },
    downloadLabelsClick() {
      const url = `https://api2.bftlogistik.dk:7100/pdflabel/multi/v1/${this.shipment.userId}/${this.shipment.shipmentId}`;
      window.open(url);
    },
    optionsClick() {
      this.options.visible = true;
    },
    saveDeliveryOption(optionId) {
      this.options.visible = false;
      const option = this.deliveryOptions.find(
        (option) => option.id === optionId
      );
      this.trackAdvancedStore.saveDeliveryOption(
        this.loginStore.auth,
        this.shipment.shipmentId,
        option
      );
    },
  },
};
</script>

<style>
.track-advanced-map {
  width: 80%;
  max-width: 1100px;
  height: 500px;
}

@media screen and (min-width: 768px) {
  .track-advanced-buttons {
    padding-left: 30px;
  }
}
</style>
