<template>
  <div class="kolli-list">
    <div class="kolli-list__bar">
      <div class="kolli-list__bar-item kolli-list__bar-item--amount">
        Samlet antal kolli
      </div>
      <div class="kolli-list__bar-item kolli-list__bar-item--weight">
        Samlet vægt
      </div>
      <div class="kolli-list__bar-item kolli-list__bar-item--volume">
        Samlet volumen
        <img
          class="kolli-list__info-icon"
          src="images/kolli-list-info.svg"
          v-tooltip="{
            content: `<strong>Beregning af volumen (m3) i meter:</strong><br>
                      Længde x bredde x højde = volumen (m3)<br>
                      Eksempel: (1,60 m x 0,60 m x 0,80 m) = 0,768 m3`,
            offset: 8,
          }"
        />
      </div>
      <div class="kolli-list__bar-item kolli-list__bar-item--description">
        Pakkens indhold
      </div>
    </div>
    <div class="kolli-list__content">
      <div
        class="kolli-item-row"
        v-for="(item, index) in items"
        :key="`kolli-item-row-${index}`"
      >
        <div class="kolli-item kolli-item--amount">
          <form-field placeholder="Antal kolli*" v-model="item.amount" />
        </div>
        <div class="kolli-item kolli-item--weight">
          <form-field placeholder="Vægt i kg*" v-model="item.weight" />
        </div>
        <div class="kolli-item kolli-item--volume">
          <form-field placeholder="Volumen i m3*" v-model="item.volume" />
        </div>
        <div class="kolli-item kolli-item--description">
          <form-field
            placeholder="Skriv hvad pakken indeholder*"
            v-model="item.description"
          />
        </div>
        <div
          class="kolli-item kolli-item--delete"
          @click="$emit('delete-kolli', item)"
        >
          <img src="images/trash.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="kolli-list__actions">
      <div class="kolli-list__btn" @click="$emit('add-kolli')">
        Tilføj linie
        <img
          class="kolli-list__btn-icon"
          src="images/kolli-list-btn-icon.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormField from "@/components/FormField";
export default {
  name: "KolliList",
  components: { FormField },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>

<style>
.kolli-list__bar {
  display: flex;
  font-weight: bold;
  padding-bottom: 20px;
  padding-right: 40px;
}
.kolli-list__bar-item {
  width: calc(100% / 3);
}
.kolli-list__bar-item.kolli-list__bar-item--description {
  display: none;
}
.kolli-item-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-right: 40px;
  position: relative;
}
.kolli-item.kolli-item--amount {
  width: calc(100% / 3 - 5px);
  margin-right: 5px;
}
.kolli-item.kolli-item--weight {
  width: calc(100% / 3 - 5px);
  margin-right: 5px;
}
.kolli-item.kolli-item--volume {
  width: calc(100% / 3);
}
.kolli-list__bar-item--volume img {
  vertical-align: middle;
  margin-top: -2px;
  margin-left: 3px;
  width: 21px;
  height: auto;
}
.kolli-item.kolli-item--description {
  width: 100%;
}
.kolli-item.kolli-item--delete {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}
.kolli-list__btn {
  display: inline-block;
  position: relative;
  background-color: #56bae0;
  color: #fff;
  height: 42px;
  line-height: 42px;
  padding: 0 100px 0 20px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.1 ease;
}
.kolli-list__btn-icon {
  position: absolute;
  top: 3px;
  right: 10px;
}
.kolli-list__actions {
  padding-top: 30px;
  text-align: center;
}
.kolli-list__info-icon {
  cursor: pointer;
}

/* TODO: Remove when layout is complete */

/* @media screen and (min-width: 768px) {
  .kolli-list__bar-item.kolli-list__bar-item--amount {
    width: 24%;
  }
  .kolli-list__bar-item.kolli-list__bar-item--weight {
    width: 24%;
  }
  .kolli-list__bar-item.kolli-list__bar-item--volume {
    width: 24%;
  }
  .kolli-list__bar-item.kolli-list__bar-item--description {
    display: block;
    width: 28%;
  }

  .kolli-item.kolli-item--amount {
    width: calc(24% - 10px);
    margin-right: 10px;
  }
  .kolli-item.kolli-item--weight {
    width: calc(24% - 10px);
    margin-right: 10px;
  }
  .kolli-item.kolli-item--volume {
    width: calc(24% - 10px);
    margin-right: 10px;
    margin-left: 0;
  }
  .kolli-item.kolli-item--description {
    width: 28%;
  }
} */
</style>
