<template>
  <div class="app-footer">
    <div class="app-footer-column app-footer-column--contact">
      <div class="app-footer__header">Kontakt</div>
      <div class="app-footer__content">
        BFT Logistik og Ejendomme ApS<br />
        Theilgaards Torv 9<br />
        DK – 4600 Køge<br />
        cvr. 38913875
      </div>
    </div>
    <div class="app-footer-column app-footer-column--services">
      <div class="app-footer__header">Services</div>
      <ul class="app-footer__links">
        <li class="app-footer__link">
          <a href="https://bftlogistik.dk/services/transport/" target="_blank"
            >Transport</a
          >
        </li>
        <li class="app-footer__link">
          <a href="https://bftlogistik.dk/services/kundecenter/" target="_blank"
            >Kundecenter</a
          >
        </li>
        <li class="app-footer__link">
          <a
            href="https://bftlogistik.dk/services/lager-logistik/"
            target="_blank"
            >Lager/logistik</a
          >
        </li>
      </ul>
    </div>
    <div class="app-footer-column app-footer-column--info">
      <div class="app-footer__header">Generel information</div>
      <ul class="app-footer__links">
        <li class="app-footer__link">
          <a href="https://bftlogistik.dk/om-bft/" target="_blank">Om BFT</a>
        </li>
        <li class="app-footer__link">
          <a href="https://bftlogistik.dk/kontakt/" target="_blank">Kontakt</a>
        </li>
        <li class="app-footer__link">
          <a href="https://bftlogistik.dk/privatlivspolitik/" target="_blank"
            >Privatlivspolitik</a
          >
        </li>
      </ul>
    </div>
    <div class="app-footer-column app-footer-column--trustpilot">
      <!-- TrustBox widget - Review Collector -->
      <div
        style="margin-left: -75px"
        class="trustpilot-widget"
        data-locale="da-DK"
        data-template-id="56278e9abfbbba0bdcd568bc"
        data-businessunit-id="575eedaf0000ff0005908305"
        data-style-height="52px"
        data-style-width="100%"
      >
        <a
          href="https://dk.trustpilot.com/review/bftlogistik.dk"
          target="_blank"
          rel="noopener noreferrer"
          >Trustpilot</a
        >
      </div>
      <!-- End TrustBox widget -->
    </div>
    <div class="app-footer-column app-footer-column--legal">
      © 2020 BFT Logistik. Alle rettigheder forbeholdt.<br /><br /><a
        href="https://bftlogistik.dk/cookiepolitik/"
        target="_blank"
        >Cookiepolitik</a
      >
      <a href="https://bftlogistik.dk/cookiedeklaration/" target="_blank"
        >Cookiedeklaration</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style>
.app-footer__header {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 17px;
  margin-top: 40px;
  margin-bottom: 10px;
}
.app-footer__content {
  font-size: 14px;
  line-height: 20px;
}
.app-footer__links {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.app-footer__link a {
  color: #fff;
  text-decoration: none;
}
.app-footer__link a:before {
  display: inline-block;
  content: "";
  width: 12px;
  height: 12px;
  background: url("../assets/chevron-right--white.svg");
  background-repeat: no-repeat;
  margin-right: 8px;
}
.app-footer__link {
  margin: 0 0 10px 0;
  padding: 0;
  color: #fff;
  list-style-type: none;
}
.app-footer-column--legal {
  width: 100%;
  border-top: 1px solid #fff;
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 50px;
  text-align: center;
}
.app-footer-column--legal a {
  color: #fff;
  text-decoration: none;
  margin: 0 20px;
}
.app-footer-column--trustpilot {
  margin-top: 80px;
  margin-left: 40px;
  width: 180px;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .app-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .app-footer-column--contact {
    margin-right: 60px;
  }
  .app-footer-column--services {
    margin-right: 60px;
  }
  .app-footer-column--trustpilot {
    margin-top: 80px;
    margin-left: 40px;
    width: 100%;
    text-align: center;
  }
}
@media screen and (min-width: 1024px) {
  .app-footer-column--contact,
  .app-footer-column--services,
  .app-footer-column--info {
    margin-right: 6%;
  }
  .app-footer-column--trustpilot {
    width: auto;
    text-align: left;
  }
}
@media (hover: hover) {
  .app-footer-column--legal a:hover {
    transition: opacity 0.1s ease-out;
    opacity: 0.6;
  }
  .app-footer__link a:hover {
    transition: opacity 0.1s ease-out;
    opacity: 0.6;
  }
}
</style>
