<template>
  <div
    ref="modaloverlay"
    :class="{ 'modal-overlay': true, 'modal-overlay--center': center }"
  >
    <a class="close" v-if="closeEnabled" @click="$emit('close')"
      ><img src="images/xmark-large.svg" alt="close"
    /></a>
    <slot />
  </div>
</template>

<script>
export default {
  name: "ModalOverlay",
  props: {
    center: {
      type: Boolean,
      default: false,
    },
    closeEnabled: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$refs.modaloverlay.focus();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(7, 26, 36, 0.9);
  overflow-y: auto;
  padding-top: 75px;
}

.modal-overlay--center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
}

.close {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  padding: 8px 0 0 8px;
}
.close img {
  width: 24px;
  height: 24px;
}
</style>
