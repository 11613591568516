<template>
  <button
    :class="buttonClasses"
    :style="{ width: width }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <img v-if="icon" class="btn__icon" :src="icon" alt="Button Icon" />
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "CustomButton",
  props: {
    icon: String,
    label: { type: String, default: "" },
    width: String,
    outline: Boolean,
    disabled: Boolean,
    center: Boolean,
    right: Boolean,
    compact: Boolean,
  },
  computed: {
    buttonClasses() {
      return {
        btn: true,
        "btn--outline": this.outline,
        "btn--icon": this.icon,
        "btn--center": this.center,
        "btn--right": this.right,
        "btn--compact": this.compact,
      };
    },
  },
};
</script>

<style>
.btn {
  position: relative;
  background-color: #f15726;
  color: #fff;
  height: 42px;
  padding: 0 20px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.1s;
}
.btn:disabled {
  border: 1px solid #fff;
  background: transparent;
  cursor: default;
  opacity: 0.5;
}
.btn.btn--icon {
  margin: 0 auto;
}
.btn.btn--center {
  display: block;
  margin: 0 auto;
}
.btn.btn--right {
  display: block;
  margin-left: auto;
}
.btn.btn--compact {
  padding: 0 13px;
  height: 26px;
  border-radius: 4px;
}
.btn.btn--outline {
  background-color: transparent;
  border: 1px solid #fff;
}
.btn__icon {
  height: 20px;
  vertical-align: middle;
  margin-right: 10px;
}

@media (hover: hover) {
  .btn:hover {
    background-color: #ff8761;
  }
  .btn.btn:disabled:hover {
    background-color: transparent;
  }
  .btn.btn--outline:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
</style>
