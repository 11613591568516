<template>
  <div class="address-form">
    <form-field-group label="Navn eller firmanavn">
      <form-field
        className="form-field__name"
        placeholder="Fulde navn eller firmanavn*"
        v-model="formContent.name"
        :valid="formContent.name === null || !$v.formContent.name.$invalid"
      />
    </form-field-group>
    <form-field-group label="Kontaktperson (ikke påkrævet)">
      <form-field
        className="form-field__contactPerson"
        placeholder="Kontaktpersons fulde navn"
        v-model="formContent.contactPerson"
      />
    </form-field-group>
    <form-field-group label="Adresse">
      <form-field
        className="form-field__address"
        placeholder="Vejnavn, Husnr., Etage*"
        v-model="formContent.address"
        :valid="
          formContent.address === null || !$v.formContent.address.$invalid
        "
      />
      <form-field
        className="form-field__zip"
        placeholder="Postnummer*"
        v-model="formContent.zip"
        :valid="formContent.zip === null || !$v.formContent.zip.$invalid"
      />
      <form-field
        className="form-field__city"
        placeholder="By*"
        v-model="formContent.city"
        :valid="formContent.city === null || !$v.formContent.city.$invalid"
      />
    </form-field-group>
    <form-field-group label="Kontaktoplysninger">
      <form-field
        className="form-field__email"
        placeholder="Mailadresse*"
        v-model="formContent.email"
        :valid="formContent.email === null || !$v.formContent.email.$invalid"
      />
      <form-field
        className="form-field__mobile-phone"
        placeholder="Mobilnummer*"
        v-model="formContent.mobilePhone"
        :valid="
          formContent.mobilePhone === null ||
          !$v.formContent.mobilePhone.$invalid
        "
      />
    </form-field-group>
  </div>
</template>

<script>
import { required, email, numeric } from "vuelidate/lib/validators";
import FormFieldGroup from "@/components/FormFieldGroup";
import FormField from "@/components/FormField";

export default {
  name: "AddressForm",
  components: { FormFieldGroup, FormField },
  data() {
    return {
      formContent: {
        name: null,
        contactPerson: null,
        address: null,
        address2: "",
        zip: null,
        city: null,
        email: null,
        mobilePhone: null,
      },
    };
  },
  watch: {
    formContent: {
      deep: true,
      handler() {
        this.$emit("update", this.formContent);
        this.$emit("validation", !this.$v.$invalid);
      },
    },
  },
  validations: {
    formContent: {
      name: { required },
      address: { required },
      zip: { required, numeric },
      city: { required },
      email: { required, email },
      mobilePhone: { required },
    },
  },
};
</script>

<style>
.form-field__street {
  width: 70%;
  padding-right: 5px;
}
.form-field__streetnumber {
  width: 30%;
  padding-left: 5px;
}
.form-field__zip {
  width: 30%;
  padding-right: 5px;
}
.form-field__city {
  width: 70%;
  padding-left: 5px;
}

@media screen and (min-width: 768px) {
  .form-field__street {
    width: calc(35% - 5px);
    padding-right: 5px;
  }
  .form-field__streetnumber {
    width: 15%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .form-field__floor {
    width: 15%;
    padding-left: 5px;
    margin-right: 30%;
  }
  .form-field__zip {
    width: 15%;
  }
  .form-field__city {
    width: calc(35% - 10px);
  }
  .form-field__email {
    width: 50%;
    padding-right: 5px;
  }
  .form-field__mobilePhone {
    width: 30%;
    padding-left: 5px;
  }
}
</style>
