import { defineStore } from "pinia";
import axios from "axios";

const trackEndpoint = `${window["bft-settings"].apiUrl}/orders/`;

export const useTrackStore = defineStore("track", {
  state: () => ({
    loaded: false,
    statusItems: [
      { id: "data", label: "Data modtaget" },
      { id: "received", label: "Forsendelse modtaget" },
      { id: "planned", label: "Rute planlagt" },
      { id: "delivering", label: "På vej til dig" },
      { id: "delivered", label: "Leveret" },
    ],
    shipment: null,
  }),
  actions: {
    async loadData(id) {
      try {
        const response = await axios.get(trackEndpoint + "anonymous/" + id);
        this.loaded = true;
        this.shipment = response.data;
      } catch (error) {
        this.loaded = true;
        console.log(error);
      }
    },
    async saveDeliveryOption(id, deliveryOption) {
      try {
        const response = await axios.put(
          trackEndpoint + id + "/deliveryoption",
          deliveryOption
        );
        this.shipment = response.data;
        this.loaded = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
