import { defineStore } from "pinia";
import axios from "axios";

const createEndpoint = `${window["bft-settings"].apiUrl}/orders`;

export const useCreateStore = defineStore("create", {
  state: () => ({
    loaded: true,
    submitted: false,
    order: null,
  }),
  actions: {
    async createOrder(auth, order) {
      try {
        this.loaded = false;
        this.submitted = false;
        const response = await axios.post(createEndpoint, order, {
          headers: { Authorization: `Basic ${auth}` },
        });

        this.loaded = true;
        this.order = response.data;

        if (response.status === 200) {
          this.submitted = true;
        }
      } catch (error) {
        this.loaded = true;
        this.submitted = true;
        console.log(error);
      }
    },
  },
});
