<template>
  <div class="statusbar-item" :style="{ width: item.width }">
    <div class="statusbar-item__icon">
      <img class="statusbar-item__icon-image" :src="iconSrc" />
      <img
        v-if="completed"
        class="statusbar-item__icon-checkmark"
        src="images/checkmark.svg"
      />
    </div>
    <div class="statusbar-item__label">{{ item.label }}</div>
  </div>
</template>

<script>
export default {
  name: "StatusbarItem",
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconSrc() {
      return `images/statusicons/delivery-status-${this.item.id}.svg`;
    },
  },
};
</script>

<style>
.statusbar-item {
  padding: 0 8px;
}
.statusbar-item__icon {
  position: relative;
  height: 58px;
  width: 100%;
  padding: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.statusbar-item__icon-image {
  width: 36px;
  height: auto;
}
.statusbar-item__icon-checkmark {
  position: absolute;
  left: calc(50% + 10px);
  top: 10px;
  width: 16px;
  height: auto;
}
.statusbar-item__label {
  margin-top: 10px;
  font-size: 10px;
  text-align: center;
}

@media screen and (min-width: 680px) {
  .statusbar-item__icon {
    height: 78px;
  }
  .statusbar-item__icon-image {
    width: 46px;
  }
  .statusbar-item__icon-checkmark {
    left: calc(50% + 15px);
    top: 15px;
  }
  .statusbar-item__label {
    font-size: 11px;
  }
}
</style>
