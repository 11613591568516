<template>
  <div class="create">
    <loading-indicator v-if="!servicesStore.loaded" />
    <div class="create-content" v-if="servicesStore.loaded">
      <row>
        <div class="create-container">
          <toggle-buttons
            :header="togglebuttons.header"
            :items="togglebuttons.items"
            :current="togglebuttons.current"
            @toggle="buttonToggled"
          />
          <expand-panel
            :index="1"
            label="Ordre og dato"
            :current="expandPanel.current"
            @toggle="expandPanelToggle"
          >
            <order-date-form
              ref="orderAndDate"
              :order-type="togglebuttons.items[togglebuttons.current].label"
              @update="orderDateFormUpdate"
              @validation="(value) => (validations.orderAndDate = value)"
            />
            <btn
              :disabled="!validations.orderAndDate"
              label="Næste"
              width="200px"
              right
              @click="nextClick"
            />
          </expand-panel>
          <expand-panel
            :index="2"
            :label="expandPanel.titles[togglebuttons.current].label"
            :current="expandPanel.current"
            @toggle="expandPanelToggle"
          >
            <address-form
              @update="addressFormUpdate"
              @validation="(value) => (validations.address = value)"
            />
            <btn
              :disabled="!validations.address"
              label="Næste"
              width="200px"
              right
              @click="nextClick"
            />
          </expand-panel>
          <expand-panel
            :index="3"
            label="Serviceydelser"
            :current="expandPanel.current"
            @toggle="expandPanelToggle"
          >
            <div v-show="deliveryDate">
              <table-select
                header="Leveringspræference"
                :items="freightServices"
                :current="services.currentFreightService"
                @toggle="freightServiceToggle"
              />
              <time-picker v-if="hasTimeInterval" @time-change="timeChange" />
              <table-select
                :items="additionalServices"
                :current="services.currentAdditionalService"
                @toggle="additionalServiceToggle"
              />
              <btn
                :disabled="!validations.services"
                label="Næste"
                width="200px"
                right
                @click="nextClick"
              />
            </div>
            <div style="text-align: center" v-show="!deliveryDate">
              Vælg en dato under "Ordre og dato"
            </div>
          </expand-panel>
          <expand-panel
            :index="4"
            label="Besked til chaufføren"
            :current="expandPanel.current"
            @toggle="expandPanelToggle"
          >
            <text-message
              :placeholder="driverMessage.placeholder"
              :max-length="driverMessage.maxLength"
              v-model="driverMessage.message"
            />
            <btn label="Næste" width="200px" right @click="nextClick" />
          </expand-panel>
          <expand-panel
            :index="5"
            label="Forsendelsesoplysninger"
            :current="expandPanel.current"
            @toggle="expandPanelToggle"
          >
            <kolli-list
              :items="kolliList.items"
              @add-kolli="addKolli"
              @delete-kolli="deleteKolli"
            />
          </expand-panel>
          <br /><br /><br />
          <btn
            :disabled="!createValid"
            label="Opret ordre"
            width="200px"
            center
            @click="submitOrder"
          />
        </div>
      </row>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useServicesStore } from "../store/ServicesStore";
import { useLoginStore } from "../store/LoginStore";
import { useCreateStore } from "../store/CreateStore";
import { format } from "date-fns";
import LoadingIndicator from "@/components/LoadingIndicator";
import Row from "@/components/Row";
import Btn from "@/components/Btn";
import ToggleButtons from "@/components/ToggleButtons";
import ExpandPanel from "@/components/ExpandPanel";
import AddressForm from "@/components/AddressForm";
import OrderDateForm from "@/components/OrderDateForm";
import TableSelect from "@/components/TableSelect";
import TextMessage from "@/components/TextMessage";
import KolliList from "@/components/KolliList";
import TimePicker from "@/components/TimePicker";

const SATURDAY = 6;
const SUNDAY = 0;

export default {
  name: "Create",
  components: {
    Row,
    Btn,
    LoadingIndicator,
    ToggleButtons,
    ExpandPanel,
    AddressForm,
    OrderDateForm,
    TableSelect,
    TextMessage,
    KolliList,
    TimePicker,
  },
  data() {
    // Dummy data
    return {
      submitting: false,
      orderNumber: null,
      togglebuttons: {
        header: "Vælg mellem levering, returnering og afhentning",
        items: [
          { label: "Levering", showActions: true },
          { label: "Returnering", showActions: false },
          { label: "Afhentning", showActions: false },
        ],
        current: 0,
      },
      deliveryDate: null,
      expandPanel: {
        current: 1,
        actionsEnabled: false,
        titles: [
          { label: "Leveringsadresse" },
          { label: "Afhentningsadresse" },
          { label: "Afhentningsadresse" },
        ],
      },
      services: {
        currentFreightService: [],
        currentAdditionalService: [],
        time: {
          from: null,
          to: null,
        },
      },
      recipient: null,
      driverMessage: {
        placeholder: "Skriv besked (Maks. 200 karakterer)",
        maxLength: 200,
        message: "",
      },
      kolliList: {
        items: [{ amount: "", weight: "", volume: "", description: "" }],
      },
      validations: {
        orderAndDate: false,
        address: false,
        services: false,
      },
    };
  },
  created() {
    this.servicesStore.loaded = false;
  },
  mounted() {
    if (this.loginStore.loggedIn) {
      this.servicesStore.loadData();
    } else {
      this.$router.replace({ name: "Login" });
    }
  },
  watch: {
    submitted(newValue) {
      if (newValue) {
        // Redirect when order has been submitted
        this.$router.push("/overview");
      }
    },
  },
  computed: {
    ...mapStores(useLoginStore, useServicesStore, useCreateStore),
    submitted() {
      return this.createStore.submitted;
    },
    currentWeekday() {
      const day = this.deliveryDate ? new Date(this.deliveryDate) : null;
      return day ? day.getDay() : null;
    },
    freightServices() {
      const filtered = this.servicesStore.services.filter((item) => {
        return item.freightService;
      });

      const mapped = filtered.map((item) => {
        return Object.assign({}, item, {
          disabled: this.isServiceDisabled(item),
        });
      });

      const sorted = mapped.sort((a, b) => {
        if (a.sort > b.sort) {
          return 1;
        }
        if (a.sort < b.sort) {
          return -1;
        }
        return 0;
      });

      return sorted;
    },
    additionalServices() {
      const filtered = this.servicesStore.services.filter(
        (item) => item.additionalService
      );

      const mapped = filtered.map((item) => {
        return Object.assign({}, item, {
          disabled: this.isServiceDisabled(item),
        });
      });

      const sorted = mapped.sort((a, b) => {
        if (a.sort > b.sort) {
          return 1;
        }
        if (a.sort < b.sort) {
          return -1;
        }
        return 0;
      });

      return sorted;
    },
    hasTimeInterval() {
      const timeIntervalService = this.services.currentFreightService.find(
        (item) => item.scnumber === "9"
      );
      return timeIntervalService !== undefined;
    },
    kolliValid() {
      // Validate kollilist
      return (
        this.kolliList.items.length > 0 &&
        this.kolliList.items.every((item) => {
          return (
            item.amount !== "" &&
            item.weight !== "" &&
            item.volume !== "" &&
            item.description !== ""
          );
        })
      );
    },
    createValid() {
      return (
        this.validations.orderAndDate &&
        this.validations.address &&
        this.validations.services &&
        this.kolliValid &&
        !this.submitting
      );
    },
  },
  methods: {
    buttonToggled(index) {
      this.togglebuttons.current = index;
    },
    expandPanelToggle(index) {
      this.expandPanel.current = index;
    },
    submitOrder() {
      const packages = this.parseKolliList(this.kolliList.items);
      const services = this.parseServices(
        this.services.currentFreightService,
        this.services.currentAdditionalService
      );
      const submission = {
        orderId: this.orderNumber,
        orderType: this.togglebuttons.current + 1,
        deliveryDate: this.deliveryDate,
        commentDriver: this.driverMessage.message,
        services: services,
        recipient: {
          name: this.recipient.name,
          contactPerson: this.recipient.contactPerson,
          address: this.recipient.address,
          address2: "",
          postalcode: this.recipient.zip,
          city: this.recipient.city,
          phone: "",
          mobilePhone: this.recipient.mobilePhone,
          email: this.recipient.email,
        },
        packages: packages,
        isTest: false,
        driveDateFrom: this.deliveryDate,
        driveDateTo: this.deliveryDate,
      };

      // console.log(JSON.stringify(submission));
      this.submitting = true;
      this.createStore.createOrder(this.loginStore.auth, submission);
    },
    addressFormUpdate(content) {
      this.recipient = content;
    },
    orderDateFormUpdate(content) {
      // Update order number
      this.orderNumber = content.orderNumber;

      // Update delivery date if different from current
      // Reset services and validation if date is changed
      const newDate = content.date
        ? format(new Date(content.date), "yyyy-MM-dd")
        : null;
      if (this.deliveryDate !== newDate) {
        this.deliveryDate = newDate;
        this.services.currentFreightService = [];
        this.services.currentAdditionalService = [];
        this.validations.services = false;
      }
    },
    nextClick() {
      this.expandPanel.current++;
    },
    freightServiceToggle(service) {
      this.services.currentFreightService = [service];
      this.validations.services = true;
    },
    timeChange(time) {
      this.services.time.from = time.from;
      this.services.time.to = time.to;
    },
    additionalServiceToggle(service) {
      // Only one or no services can be selected at a time
      if (this.services.currentAdditionalService[0] === service) {
        this.services.currentAdditionalService = [];
      } else {
        this.services.currentAdditionalService = [service];
      }
    },
    parseServices(freightServices, additionalServices) {
      const services = [...freightServices, ...additionalServices];
      const formatted = services.map((item) => {
        return {
          scid: item.scid,
          scdescription: item.scdescription,
          scnumber: item.scnumber,
          timeFrom: item.timeFrom,
          timeTo: item.timeTo,
          freightService: item.freightService,
          additionalService: item.additionalService,
          sort: item.sort,
          weekdays: item.weekdays,
          weekends: item.weekends,
        };
      });

      // Set time for Tidsbestemt levering if present
      if (this.services.time.from && this.services.time.to) {
        formatted[0].timeFrom = this.services.time.from;
        formatted[0].timeTo = this.services.time.to;
      }

      return formatted;
    },
    addKolli() {
      this.kolliList.items.push({
        amount: "",
        weight: "",
        volume: "",
        description: "",
      });
    },
    deleteKolli(item) {
      const index = this.kolliList.items.indexOf(item);
      this.kolliList.items.splice(index, 1);
    },
    parseKolliList(kolliList) {
      const packages = [];
      kolliList.forEach((item) => {
        let weight = item.weight.replace(",", ".");
        weight = parseFloat(weight) / item.amount;
        weight = Math.round(weight * 100) / 100;

        let volume = item.volume.replace(",", ".");
        volume = parseFloat(volume) / item.amount;
        volume = Math.round(volume * 100) / 100;

        for (let i = 0; i < item.amount; i++) {
          const pack = {
            weight: weight,
            volume: volume,
            contents: item.description,
          };
          packages.push(pack);
        }
      });
      return packages;
    },
    isServiceDisabled(service) {
      if (this.currentWeekday) {
        if (
          this.currentWeekday === SATURDAY ||
          this.currentWeekday === SUNDAY
        ) {
          return !service.weekends;
        } else {
          return !service.weekdays;
        }
      }
      return false;
    },
  },
};
</script>

<style>
.create-container {
  padding: 30px 0;
  max-width: 812px;
  margin: 0 auto;
}
</style>
