<template>
  <div id="app">
    <row>
      <topbar
        :logged-in="loginStore.loggedIn"
        :navigation-items="topbarNavigation.items"
        :navigation-available="navigationAvailable"
        @log-in-click="logIn"
        @log-out-click="logOut"
      />
    </row>
    <router-view />
    <row background-color="#014963"><app-footer /></row>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useLoginStore } from "./store/LoginStore";
import Row from "@/components/Row";
import Topbar from "@/components/Topbar";
import AppFooter from "@/components/AppFooter";

export default {
  name: "App",
  components: { Row, Topbar, AppFooter },
  data() {
    return {
      topbarNavigation: {
        items: [
          {
            title: "Overblik",
            routes: ["Overview", "TrackAdvanced"],
            path: "/overview",
          },
          {
            title: "Opret ordre",
            routes: ["Create"],
            path: "/create",
          },
        ],
      },
    };
  },
  computed: {
    ...mapStores(useLoginStore),
    navigationAvailable() {
      return this.$route.name !== "Track" && this.$route.name !== "Login";
    },
  },
  methods: {
    logIn() {
      this.$router.push({ name: "Login" });
    },
    logOut() {
      this.loginStore.logOut();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
html {
  background-color: #225268;
}
body {
  color: #ffffff;
}
#app {
  font-family: "Raleway", sans-serif;
}
@media print {
  .hide-print {
    display: none;
  }
}
</style>
