<template>
  <div class="topbar">
    <div class="topbar-column topbar-column--logo">
      <img class="topbar-logo" src="images/bft-logo.svg" />
    </div>
    <div v-if="loggedIn" class="topbar-column topbar-column--nav">
      <topbar-navigation v-if="navigationVisible" :items="navigationItems" />
    </div>
    <div class="topbar-column topbar-column--button">
      <div v-if="navigationAvailable">
        <btn v-if="loggedIn" label="Log ud" @click="$emit('log-out-click')" />
      </div>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/Btn.vue";
import TopbarNavigation from "@/components/TopbarNavigation.vue";

export default {
  name: "Topbar",
  components: {
    Btn,
    TopbarNavigation,
  },
  props: {
    loggedIn: { type: Boolean, default: false },
    navigationItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
    navigationAvailable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    navigationVisible() {
      return (
        this.loggedIn &&
        this.navigationItems.length > 0 &&
        this.navigationAvailable
      );
    },
  },
};
</script>

<style>
.topbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.topbar-column.topbar-column--nav {
  width: 100%;
  order: 4;
}
.topbar-logo {
  width: 104px;
}

@media screen and (min-width: 768px) {
  .topbar-column.topbar-column--nav {
    width: auto;
    order: 0;
  }
}

@media print {
  .topbar {
    display: none;
  }
}
</style>
