<template>
  <div class="order-date-form">
    <form-field-group label="Ordrenummer">
      <form-field
        className="form-field__order-number"
        placeholder="Ordrenummer*"
        v-model="formContent.orderNumber"
        :valid="
          formContent.orderNumber === null ||
          !$v.formContent.orderNumber.$invalid
        "
      />
    </form-field-group>
    <form-field-group :label="dateHeader">
      <date-picker
        v-model="formContent.date"
        placeholder="Vælg dato"
        :disabled-date="disabledDate"
      ></date-picker>
    </form-field-group>
  </div>
</template>

<script>
import FormFieldGroup from "@/components/FormFieldGroup";
import FormField from "@/components/FormField";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/da";
import { required } from "vuelidate/lib/validators";

export default {
  name: "OrderDateForm",
  components: { FormFieldGroup, FormField, DatePicker },
  props: {
    orderType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formContent: {
        orderNumber: null,
        date: "",
      },
    };
  },
  watch: {
    formContent: {
      deep: true,
      handler() {
        this.$emit("update", this.formContent);
        this.$emit("validation", !this.$v.$invalid);
      },
    },
  },
  validations: {
    formContent: {
      orderNumber: { required },
      date: { required },
    },
  },

  computed: {
    dateHeader() {
      return `Dato for ${this.orderType.toLowerCase()}`;
    },
  },
  methods: {
    disabledDate(inputDate) {
      const currentDate = new Date();

      // Check if input date is prior to current date
      if (inputDate < currentDate) {
        return true;
      }

      // Check if input date is same as current date
      if (inputDate.toDateString() === currentDate.toDateString()) {
        return true;
      }

      // Check if input date is next day and current time is 4pm or later
      const nextDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1
      );
      if (
        inputDate.toDateString() === nextDay.toDateString() &&
        currentDate.getHours() >= 16
      ) {
        return true;
      }

      // Check if input date is a Sunday
      if (inputDate.getDay() === 0) {
        return true;
      }

      // If none of the above scenarios match, return false
      return false;
    },
  },
};
</script>

<style>
@media screen and (min-width: 768px) {
}
</style>
