import { defineStore } from "pinia";
import axios from "axios";

const loginEndpoint = `${window["bft-settings"].apiUrl}/users/isvalid`;

export const useLoginStore = defineStore("login", {
  state: () => ({
    loaded: true,
    loggedIn: false,
    auth: null,
    username: null,
  }),
  actions: {
    async logIn(login) {
      try {
        this.loaded = false;
        this.username = login.username;

        // Base64 encode the username and password
        const auth = btoa(login.username + ":" + login.password);

        // Get orders to test if login is valid
        // Set auth for future use if login is valid
        const response = await axios.get(loginEndpoint, {
          headers: { Authorization: `Basic ${auth}` },
        });
        this.loaded = true;
        if (response.status === 200) {
          this.loggedIn = true;
          this.auth = auth;
        } else {
          this.loggedIn = false;
          this.auth = null;
          console.log("invalid login");
        }
      } catch (error) {
        this.loaded = true;
        console.log(error);
      }
    },
    logOut() {
      this.loggedIn = false;
      this.username = null;
      this.auth = null;
    },
  },
});
