<template>
  <div class="overview-table-headline">
    <span class="overview-table-headline__total">{{ formattedTotal }}</span>

    {{ shipmentsString }} i perioden
    <span class="overview-table-headline__period"> {{ dateString }}</span>
  </div>
</template>

<script>
import { format } from "date-fns";
import { da } from "date-fns/locale";

export default {
  name: "OverviewTableHeadline",
  props: {
    total: {
      type: Number,
      required: true,
    },
    from: {
      type: Date,
      required: true,
    },
    to: {
      type: Date,
      required: true,
    },
  },
  computed: {
    formattedTotal() {
      return this.total.toLocaleString("da-DK");
    },
    shipmentsString() {
      return this.total === 1 ? "forsendelse" : "forsendelser";
    },
    dateString() {
      return `${format(new Date(this.from), "d. MMMMMM yyyy", {
        locale: da,
      })} - ${format(new Date(this.to), "d. MMMMMM yyyy", { locale: da })}`;
    },
  },
};
</script>

<style>
.overview-table-headline {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 4px;
}
.overview-table-headline__total,
.overview-table-headline__period {
  font-weight: bold;
}
</style>
