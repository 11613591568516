import { defineStore } from "pinia";
import axios from "axios";

const overviewEndpoint = `${window["bft-settings"].apiUrl}/orders`;

export const useOverviewStore = defineStore("overview", {
  state: () => ({
    loaded: false,
    overview: null,
  }),
  actions: {
    async search(auth, query) {
      try {
        this.loaded = false;
        const queryString = new URLSearchParams(query).toString();
        const response = await axios.get(
          `${overviewEndpoint}?${queryString}&limit=20`,
          {
            headers: { Authorization: `Basic ${auth}` },
          }
        );
        this.loaded = true;
        this.overview = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
