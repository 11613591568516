<template>
  <div class="recipient-card">
    <div class="recipient-card-order">
      <div class="recipient-card-order__header">Ordrenummer</div>
      <div class="recipient-card-order__number">
        {{ recipient.orderNumber }}
      </div>
    </div>
    <div class="recipient-card-recipient__header">Modtagerinformation</div>
    <div class="recipient-card-recipient">
      <div class="recipient-card-recipient-column1">
        <div class="recipient-card-recipient__name-header">Navn</div>
        <div class="recipient-card-recipient__name">{{ recipient.name }}</div>
        <div class="recipient-card-recipient__address-header">Adresse</div>
        <div class="recipient-card-recipient__address">
          {{ recipient.address }}
        </div>
        <div class="recipient-card-recipient__address2">
          {{ recipient.address2 }}
        </div>
        <div class="recipient-card-recipient__zip-city">
          {{ recipient.postalcode }} {{ recipient.city }}
        </div>
        <div class="recipient-card-recipient__country">
          {{ recipient.country }}
        </div>
        <div class="recipient-card-recipient__phone-header">Telefonnummer</div>
        <div class="recipient-card-recipient__phone">
          {{ recipient.mobilePhone }}
        </div>
      </div>
      <div class="recipient-card-recipient-column2">
        <div class="recipient-card-recipient__createddate-header">
          Oprettelsesdato
        </div>
        <div class="recipient-card-recipient__createddate">
          {{ formattedCreatedDate }}
        </div>
        <div class="recipient-card-recipient__createdtime">
          {{ formattedCreatedTime }}
        </div>
        <div class="recipient-card-recipient__bookeddate-header">
          {{ bookedDeliveryDateLabel }}
        </div>
        <div class="recipient-card-recipient__bookeddate">
          {{ formattedBookedDate }}
        </div>
        <div class="recipient-card-recipient__bookedtime">
          {{ recipient.timeFrom }}-{{ recipient.timeTo }}
        </div>
        <div class="recipient-card-recipient__services-header">
          Booket services
        </div>
        <div
          v-for="(service, index) in recipient.services"
          :key="`service-${index}`"
          class="recipient-card-recipient__service"
        >
          {{ service.scdescription }}
        </div>
      </div>
      <div class="recipient-card-recipient-column3">
        <div class="recipient-card-recipient__comment-header">
          Kommentar til chauffør
        </div>
        <div class="recipient-card-recipient__comment">
          {{ recipient.comment || "-" }}
        </div>
        <div
          v-if="placement.length > 0"
          class="recipient-card-recipient__placement-header"
        >
          Placering
        </div>
        <div
          v-if="placement.length > 0"
          class="recipient-card-recipient__placement"
        >
          {{ placement }}
        </div>
      </div>
    </div>

    <div class="recipient-card-actions" v-if="canChangePlacement">
      <div class="recipient-card-actions__support">
        <btn
          label="Vælg placering"
          width="100%"
          @click="$emit('options-click')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { da } from "date-fns/locale";

import Btn from "@/components/Btn";
export default {
  name: "RecipientCard",
  components: {
    Btn,
  },
  props: {
    orderType: {
      type: Number,
      required: true,
    },
    recipient: {
      type: Object,
      default: function () {
        return {};
      },
    },
    placement: {
      type: String,
    },
    canChangePlacement: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedCreatedDate() {
      return format(new Date(this.recipient.created), "d. MMMMMM yyyy", {
        locale: da,
      });
    },
    formattedCreatedTime() {
      return format(new Date(this.recipient.created), "HH:mm", {
        locale: da,
      });
    },
    formattedBookedDate() {
      return format(new Date(this.recipient.date), "d. MMMMMM yyyy", {
        locale: da,
      });
    },
    bookedDeliveryDateLabel() {
      return this.orderType === 1 ? "Booket lev. dato" : "Booket afh. dato";
    },
  },
};
</script>

<style>
.recipient-card {
  margin-top: 30px;
  background-color: #fff;
  color: #1d4962;
  padding: 20px 16px;
  border-radius: 8px;
}
/* Order */
.recipient-card-order {
  margin-bottom: 20px;
}
.recipient-card-order__header {
  font-weight: bold;
  text-transform: uppercase;
}
.recipient-card-order__number {
  font-size: 25px;
  font-weight: bold;
  color: #05bce4;
}
/* Recipient */
.recipient-card-recipient__header {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.recipient-card-recipient__name-header,
.recipient-card-recipient__address-header,
.recipient-card-recipient__phone-header,
.recipient-card-recipient__bookeddate-header,
.recipient-card-recipient__createddate-header,
.recipient-card-recipient__services-header,
.recipient-card-recipient__comment-header,
.recipient-card-recipient__placement-header {
  font-weight: bold;
  margin-top: 16px;
}

/* Actions */
.recipient-card-actions {
  margin-top: 30px;
}
.recipient-card-actions__support {
  margin-bottom: 10px;
}

@media screen and (min-width: 1024px) {
  .recipient-card-recipient {
    display: flex;
    flex-wrap: wrap;
  }

  .recipient-card-order__number {
    font-size: 32px;
  }

  .recipient-card-recipient-column1 {
    width: 50%;
  }
  .recipient-card-recipient-column2 {
    width: 50%;
  }
  .recipient-card-recipient-column3 {
    width: 100%;
  }

  .recipient-card-actions {
    display: flex;
    justify-content: space-between;
  }
}
</style>
