<template>
  <div class="statusbar">
    <div class="statusbar-bar">
      <div class="statusbar-bar-progress" :style="{ width: `${percent}%` }" />
    </div>
    <div class="statusbar-items">
      <statusbar-item
        v-for="(item, index) in formattedItems"
        :key="item.id"
        :item="item"
        :completed="formattedCurrent > index"
      />
    </div>
  </div>
</template>

<script>
import StatusbarItem from "@/components/StatusbarItem";

export default {
  name: "Statusbar",
  components: { StatusbarItem },
  data() {
    return {
      statusItems: [
        { id: 1, label: "Data modtaget" },
        { id: 2, label: "Forsendelse modtaget" },
        { id: 3, label: "Rute planlagt" },
        { id: 4, label: "På vej til dig" },
        { id: 5, label: "Leveret" },
      ],
    };
  },
  props: {
    current: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    percent() {
      return (this.formattedCurrent / this.statusItems.length) * 100;
    },
    formattedCurrent() {
      // If status code is above 5 not items should be active
      return this.current < 6 ? this.current : 0;
    },
    formattedItems() {
      const itemWidth = 100 / this.statusItems.length + "%";
      return this.statusItems.map((item, index) => {
        return Object.assign({}, item, {
          width: itemWidth,
          completed: this.current > index,
        });
      });
    },
  },
};
</script>

<style>
.statusbar {
  position: relative;
  height: 100px;
  max-width: 680px;
  margin: 0 auto;
}
.statusbar-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 58px;
  border-radius: 8px;
  border: 1px solid #56bae0;
  overflow: hidden;
}
.statusbar-bar-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 58px;
  background-color: #56bae0;
}
.statusbar-items {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  display: flex;
}

@media screen and (min-width: 680px) {
  .statusbar {
    height: 110px;
  }
  .statusbar-bar {
    height: 78px;
  }
  .statusbar-bar-progress {
    height: 78px;
  }
}
</style>
