<template>
  <div class="overview-table-bar">
    <slot />
  </div>
</template>

<script>
export default {
  name: "OverviewTableBar",
};
</script>

<style>
@media screen and (min-width: 768px) {
  .overview-table-bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
