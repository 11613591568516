<template>
  <div class="package-status-table">
    <div v-if="rows.length === 0" class="package-status-table-empty">
      Pakken har ingen statuser
    </div>
    <div
      v-for="(row, index) in sortedRows"
      :key="`row-${index}`"
      :class="{
        'package-status-table-row': true,
        'package-status-table-row--active': index === 0,
      }"
    >
      <div class="package-status-table-column1">
        <img class="package-status-table__icon" :src="statusIcon(index)" />
        <div class="package-status-table-row__date">
          {{ formatDateTime(row.dateTime) }}
        </div>
      </div>
      <div class="package-status-table-column2">
        <div class="package-status-table-row__comments">{{ row.comments }}</div>
      </div>
      <div class="package-status-table-column3">
        <div class="package-status-table-row__signature">
          <btn
            v-if="hasSignature(row)"
            label="Vis kvittering"
            outline
            compact
            @click="signatureClick(row)"
          />
        </div>

        <div class="package-status-table-row__photo">
          <btn
            v-if="hasPhoto(row)"
            label="Vis foto"
            outline
            compact
            @click="photoClick(row)"
          />
        </div>

        <div class="package-status-table-row__location">
          <btn
            v-if="hasLocation(row)"
            label="Vis på kort"
            outline
            compact
            @click="locationClick(row)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { da } from "date-fns/locale";
import Btn from "./Btn.vue";

export default {
  name: "PackageStatusTable",
  components: { Btn },
  props: {
    rows: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    sortedRows() {
      const sorted = [...this.rows];
      sorted.sort((a, b) => {
        const aTime = new Date(a.dateTime).getTime();
        const bTime = new Date(b.dateTime).getTime();

        if (aTime < bTime) {
          return 1;
        } else if (aTime > bTime) {
          return -1;
        } else {
          return 0;
        }
      });
      return sorted;
    },
  },
  methods: {
    statusIcon(index) {
      return index === 0
        ? "images/package-status-table-icon--active.svg"
        : "images/package-status-table-icon.svg";
    },
    formatDateTime(dateTime) {
      return format(new Date(dateTime), "dd.MM.yyyy - HH:mm", { locale: da });
    },
    hasSignature(row) {
      return row.signatures && row.signatures.length > 0;
    },
    hasPhoto(row) {
      return row.photos && row.photos.length > 0;
    },
    hasLocation(row) {
      return (
        row.latitude &&
        row.longitude &&
        row.latitude !== 0.0 &&
        row.longitude !== 0.0
      );
    },
    photoUrls(row) {
      if (typeof row.photos === "string") {
        return [row.photos];
      } else if (Array.isArray(row.photos)) {
        return row.photos;
      }
      return [];
    },
    signatureUrls(row) {
      if (typeof row.signatures === "string") {
        return [row.signatures];
      } else if (Array.isArray(row.signatures)) {
        return row.signatures;
      }
      return [];
    },
    signatureClick(row) {
      this.$emit("signature-click", this.signatureUrls(row));
    },
    photoClick(row) {
      this.$emit("photo-click", this.photoUrls(row));
    },
    locationClick(row) {
      this.$emit("location-click", [row.latitude, row.longitude]);
    },
  },
};
</script>

<style>
.package-status-table-empty {
  padding: 30px 0;
  border-bottom: 1px solid #fff;
  text-align: center;
  font-style: italic;
}
.package-status-table-row {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 15px;
}
.package-status-table-row:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1);
}

.package-status-table-row.package-status-table-row--active {
  color: #56bae0;
}
.package-status-table-column1 {
  display: flex;
  margin-bottom: 15px;
}
.package-status-table__icon {
  width: 30px;
  height: auto;
  margin-right: 10px;
}
.package-status-table-column2 {
  width: 100%;
  margin-bottom: 15px;
}
.package-status-table-row__comments {
  font-weight: bold;
}
.package-status-table-column3 {
  width: 100%;
  display: flex;
}
.package-status-table-row__photo {
  margin-right: 10px;
}

@media screen and (min-width: 768px) {
  .package-status-table-row {
    flex-wrap: nowrap;
    min-height: 56px;
  }
  .package-status-table-column1 {
    margin-bottom: 0;
    width: 420px;
  }
  .package-status-table-row__date {
    margin-right: 10px;
  }
  .package-status-table-column2 {
    margin-bottom: 0;
  }
  .package-status-table-row__comments {
    font-weight: bold;
  }
  .package-status-table-column3 {
    width: 300px;
    margin-right: 5px;
    display: block;
  }
  .package-status-table-row__signature {
    position: absolute;
    right: 240px;
  }
  .package-status-table-row__photo {
    position: absolute;
    right: 130px;
  }
  .package-status-table-row__location {
    position: absolute;
    right: 20px;
  }
}
</style>
