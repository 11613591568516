<template>
  <div class="image-list">
    <div v-for="image in images" :key="image" class="image-list__item">
      <img :src="image" alt="" class="image-list__image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageList",
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.image-list__item {
  padding: 5px 10px;
  text-align: center;
}

.image-list__image {
  width: 100%;
  max-width: 800px;
  height: auto;
}
</style>
