<template>
  <div class="delivery-options">
    <h3 class="delivery-options__header">Placering</h3>
    <div class="delivery-options__close" @click="$emit('close-click')">
      <img src="images/xmark-large.svg" alt="Luk" />
    </div>
    <div v-if="!confirm" class="delivery-options-form">
      <div class="delivery-options-form__select-wrapper">
        <select
          id="delivery-options-form__select"
          name="options"
          v-model="option"
          placeholder="Vælg venligst"
        >
          <option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.description }}
          </option>
        </select>
      </div>
      <div class="delivery-options-form__buttons">
        <btn label="Annuller" @click="$emit('close-click')"></btn>
        <btn label="Bekræft" @click="confirm = true"></btn>
      </div>
    </div>
    <div v-if="option && confirm" class="delivery-options-confirm">
      <div class="delivery-options-confirm__description">
        Godkend at din levering
      </div>
      <div class="delivery-options-confirm__option">
        {{ selectedOption.description }}
      </div>

      <div class="delivery-options-form__buttons">
        <btn label="Annuller" @click="confirm = false"></btn>
        <btn label="Godkend" @click="$emit('confirm-option', option)"></btn>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/Btn.vue";
export default {
  name: "DeliveryOptions",
  components: {
    Btn,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      option: 1,
      confirm: false,
    };
  },
  computed: {
    selectedOption() {
      return this.options.find((option) => option.id === this.option);
    },
  },
};
</script>

<style>
.delivery-options {
  color: #0e3242;
  background-color: #fff;
  padding: 15px 20px 20px 20px;
  width: 300px;
  border-radius: 6px;
  position: relative;
}
.delivery-options__header {
  margin: 0 0 10px 0;
  padding: 0;
  font-size: 16px;
}
.delivery-options__close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.delivery-options__close img {
  width: 16px;
  height: 16px;
}
.delivery-options-form__select-wrapper {
  position: relative;
  display: inline-block;
  margin-top: 20px;
}
.delivery-options-form__select-wrapper:after {
  content: "\0025BC";
  font-size: 12px;
  color: black;
  right: 14px;
  top: 14px;
  height: 14px;
  padding: 0;
  position: absolute;
  pointer-events: none;
}
#delivery-options-form__select {
  -webkit-appearance: none;
  padding: 10px;
  color: black;
  font-size: 16px;
  border-color: black;
}
.delivery-options-form__buttons {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.delivery-options-confirm__description {
  margin-top: 20px;
}
.delivery-options-confirm__option {
  font-weight: bold;
  margin-top: 10px;
}
</style>
