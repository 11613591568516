<template>
  <table class="overview-table">
    <tr class="overview-table-header">
      <th>Status</th>
      <th>Type</th>
      <th>Ordrenr</th>
      <th>Lev. dato</th>
      <th>Lev. tidspunkt</th>
      <th>Navn</th>
      <th>Adresse</th>
      <th>Telefon</th>
      <th>Oprettet</th>
      <th>Antal kolli</th>
      <th>Vægt (kg.)</th>
      <th>Volumen (m3)</th>
    </tr>
    <tr
      v-for="shipment in shipments"
      :key="shipment.id"
      class="overview-table-row"
      @click="$emit('shipment-click', shipment.shipmentId)"
    >
      <td
        class="overview-table__col overview-table__col--center overview-table__status"
      >
        <img
          :src="`images/statusicons/${statusIconUrl(shipment.deliveryStatus)}`"
        />
      </td>
      <td class="overview-table__col overview-table__type">
        {{ orderTypeLabel(shipment.orderType) }}
      </td>
      <td
        class="overview-table__col overview-table__col--right overview-table__shipid"
      >
        {{ shipment.orderId }}
      </td>
      <td
        class="overview-table__col overview-table__col--right overview-table__delivery-date"
      >
        {{ formatDate(shipment.deliveryDate) }}
      </td>
      <td
        class="overview-table__col overview-table__col--right overview-table__delivery-time"
      >
        {{ `${shipment.deliveryTimeFrom}-${shipment.deliveryTimeTo}` }}
      </td>
      <td
        class="overview-table__col overview-table__col--right overview-table__name"
      >
        {{ shipment.recipient.name }}
      </td>
      <td class="overview-table__col overview-table__recipient-address">
        {{ shipment.recipient.address }}<br />
        {{ shipment.recipient.postalcode }} {{ shipment.recipient.city }}
      </td>
      <td
        class="overview-table__col overview-table__col--right overview-table__phone"
      >
        {{ shipment.recipient.mobilePhone }}
      </td>
      <td
        class="overview-table__col overview-table__col--right overview-table__created-date"
      >
        <div class="overview-table__created-date">
          {{ formatDate(shipment.created) }}
        </div>
        <div class="overview-table__created-time">
          {{ formatTime(shipment.created) }}
        </div>
      </td>
      <td
        class="overview-table__col overview-table__col--right overview-table__packages"
      >
        {{ shipment.packages.length }}
      </td>
      <td
        class="overview-table__col overview-table__col--right overview-table__weight"
      >
        {{ formatWeight(shipment) }}
      </td>
      <td
        class="overview-table__col overview-table__col--right overview-table__volume"
      >
        {{ formatVolume(shipment) }}
      </td>
    </tr>
  </table>
</template>

<script>
import { format } from "date-fns";
import { da } from "date-fns/locale";

const ORDER_TYPES = ["Levering", "Retur", "Afhentning"];

export default {
  name: "OverviewTable",
  props: {
    shipments: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), "d. MMMMMM yyyy", { locale: da });
    },
    formatTime(date) {
      return format(new Date(date), "HH:mm", { locale: da });
    },
    formatWeight(shipment) {
      const sum = shipment.packages.reduce((total, item) => {
        return total + item.weight;
      }, 0);

      return `${sum.toFixed(2)} kg.`;
    },
    formatVolume(shipment) {
      const sum = shipment.packages.reduce((total, item) => {
        return total + item.volume;
      }, 0);

      return `${sum.toFixed(2)} m3`;
    },
    statusIconUrl(statusId) {
      if (statusId > 5) {
        return `delivery-status-x--inverted.svg`;
      }

      return `delivery-status-${statusId}--inverted.svg`;
    },
    orderTypeLabel(orderType) {
      return ORDER_TYPES[orderType - 1];
    },
  },
};
</script>

<style>
.overview-table {
  border: none;
  border-spacing: 1px 10px;
  margin-bottom: 40px;
}
.overview-table th {
  padding: 12px;
}
.overview-table-row {
  cursor: pointer;
}
.overview-table-row td {
  background-color: #fff;
  color: #1d4962;
  font-size: 14px;
  padding: 10px;
}
.overview-table-row td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.overview-table-row td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.overview-table__status img {
  width: 30px;
  height: auto;
}
.overview-table__col.overview-table__col--center {
  text-align: center;
}
.overview-table__col.overview-table__col--right {
  text-align: right;
}
</style>
