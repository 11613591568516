<template>
  <div class="loading-indicator">
    <div class="loading-indicator-icon">
      <img
        class="loading-indicator-icon__image"
        src="../assets/spinner-third.svg"
      />
      <div class="loading-indicator-icon__description">Henter information</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingIndicator",
  props: {},
};
</script>

<style>
.loading-indicator {
  height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-indicator-icon {
  text-align: center;
}
.loading-indicator-icon__image {
  width: 80px;
  height: auto;
  opacity: 0.8;
  animation: spin 1s infinite linear;
}
.loading-indicator-icon__description {
  margin-top: 30px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
