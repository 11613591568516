<template>
  <div class="time-picker">
    <div class="time-picker__col1">
      <span class="time-picker__label">Vælg tidsinterval</span>
    </div>
    <div class="time-picker__col2">
      <span class="time-picker__label-from">Fra</span>
      <div class="time-picker__select-wrapper">
        <select
          class="time-picker__from time-picker__select"
          name="from"
          v-model="currentFrom"
          @change="timeChange"
        >
          <option v-for="option in timesFrom" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <span class="time-picker__label-to">Til</span>
      <div class="time-picker__select-wrapper">
        <select
          class="time-picker__to time-picker__select"
          name="to"
          v-model="currentTo"
          @change="timeChange"
        >
          <option v-for="option in timesTo" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimePicker",
  data() {
    return {
      times: [
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
      ],
      currentFrom: null,
      currentTo: null,
    };
  },
  created() {
    this.currentFrom = this.times[0];
    this.currentTo = this.times[this.times.length - 1];
    this.$emit("time-change", { from: this.currentFrom, to: this.currentTo });
  },
  methods: {
    timeChange() {
      this.$emit("time-change", {
        from: this.currentFrom,
        to: this.currentTo,
      });
    },
  },
  computed: {
    currentFromIndex() {
      return this.times.indexOf(this.currentFrom);
    },
    currentToIndex() {
      return this.times.indexOf(this.currentTo);
    },
    timesFrom() {
      const start = 0;
      const end = this.currentToIndex - 3;
      return this.times.slice(start, end);
    },
    timesTo() {
      const start = this.currentFromIndex + 4;
      const end = this.times.length;
      return this.times.slice(start, end);
    },
  },
};
</script>

<style>
.time-picker {
  border: 1px solid white;
  margin-bottom: 30px;
  padding: 20px;
  margin-top: -28px;
}
.time-picker__label {
  display: block;
  margin-bottom: 20px;
}
.time-picker__label-from {
  margin-right: 10px;
}
.time-picker__label-to {
  margin-left: 20px;
  margin-right: 10px;
}
.time-picker__select-wrapper {
  position: relative;
  display: inline-block;
}
.time-picker__select-wrapper:after {
  content: "\0025BC";
  font-size: 12px;
  color: black;
  right: 12px;
  top: 12px;
  height: 14px;
  padding: 0;
  position: absolute;
  pointer-events: none;
}
.time-picker__select {
  -webkit-appearance: none;
  padding: 10px 36px 10px 10px;
  color: black;
  font-size: 16px;
  border: none;
}

@media screen and (min-width: 768px) {
  .time-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .time-picker__label {
    display: inline;
    margin-bottom: 0;
  }
}
</style>
