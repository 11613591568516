<template>
  <div class="no-results">{{ message }}</div>
</template>

<script>
export default {
  name: "NoResults",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.no-results {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
