<template>
  <ul class="pagination">
    <li
      v-for="(page, index) in pages"
      :key="index"
      :class="{
        pagination__item: true,
        'pagination__item--active': index == currentPage,
      }"
      @click="$emit('page-change', index)"
    >
      {{ index + 1 }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0 50px 0;
  list-style: none;
  flex-wrap: wrap;
}
.pagination__item {
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  margin: 4px 8px;
  color: #1d4962;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  display: block;
}
.pagination__item--active {
  background: none;
  color: #fff;
  border: 1px solid #fff;
}
</style>
