<template>
  <div id="login">
    <div class="login-panel">
      <div class="login-headers">
        <h4 class="login-headers__header">Velkommen til BFT!</h4>
      </div>
      <form @submit.prevent="submitForm" class="login-form">
        <div class="login-form-elements">
          <div class="login-form__username">
            <input
              type="text"
              name="username"
              placeholder="Indtast brugernavn"
              v-model="username"
              :class="{
                'is-invalid': submitted && $v.username.$error,
              }"
            />
            <div
              v-if="submitted && !$v.username.required"
              class="invalid-feedback"
            >
              Brugernavn skal udfyldes
            </div>
          </div>
          <div class="login-form__password">
            <input
              type="password"
              placeholder="Indtast adgangskode"
              v-model="password"
              :class="{
                'is-invalid': submitted && $v.password.$error,
              }"
            />
            <div
              v-if="submitted && !$v.password.required"
              class="invalid-feedback"
            >
              Adgangskode skal udfyldes
            </div>
          </div>
          <btn label="Log ind" width="100%" :disabled="!loginStore.loaded" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useLoginStore } from "../store/LoginStore";
import { required } from "vuelidate/lib/validators";
import Btn from "@/components/Btn.vue";

export default {
  name: "Login",
  components: { Btn },
  data() {
    return {
      username:
        process.env.NODE_ENV === "development" ? "api@bftlogistik.dk" : "",
      password: process.env.NODE_ENV === "development" ? "7BM8#xY$q35X" : "",
      submitted: false,
    };
  },
  mounted() {
    this.loginStore.logOut();
  },
  watch: {
    loggedIn(newValue) {
      if (newValue) {
        // Redirect to overview if user is logged in
        this.$router.push("/overview");
      }
    },
  },
  validations: {
    username: { required },
    password: { required },
  },
  computed: {
    ...mapStores(useLoginStore),
    loggedIn() {
      return this.loginStore.loggedIn;
    },
  },
  methods: {
    submitForm() {
      const login = {
        username: this.username,
        password: this.password,
      };
      this.loginStore.logIn(login);
    },
  },
};
</script>

<style>
#login input[type="text"],
#login input[type="password"] {
  padding: 12px;
  border: none;
  border-radius: 3px;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-panel {
  background-color: rgb(1, 73, 99);
  margin: 100px 0;
  padding: 30px 20px 50px 20px;
}
.login-headers {
  text-align: center;
  margin-bottom: 40px;
}
.login-headers__header {
  font-size: 28px;
  margin: 0;
}
.login-headers__subheader {
  font-size: 20px;
  margin: 0;
}
.login-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.login-form-elements {
  width: 100%;
  max-width: 250px;
}
.login-form__username {
  margin-bottom: 10px;
}
.login-form__username input {
  width: 100%;
}
.login-form__password {
  margin-bottom: 20px;
}
.login-form__password input {
  width: 100%;
}
</style>
