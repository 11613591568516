<template>
  <div class="sender-card">
    <div class="sender-card-order">
      <div class="sender-card-order__header">Ordrenummer</div>
      <div class="sender-card-order__number">{{ orderNumber }}</div>
      <div class="sender-card-sender__header">Afsenderinformation</div>
    </div>
    <div class="sender-card-sender">
      <div class="sender-card-sender-column1">
        <div class="sender-card-sender__company-header">Virksomhed</div>
        <div class="sender-card-sender__company">{{ company }}</div>
        <div class="sender-card-sender__packages-num-header">Antal pakker</div>
        <div class="sender-card-sender__packages-num">{{ packagesNum }}</div>
        <div
          v-if="placement.length > 0"
          class="sender-card-sender__placement-header"
        >
          Placering
        </div>
        <div v-if="placement.length > 0" class="sender-card-sender__placement">
          {{ placement }}
        </div>
      </div>
      <div class="sender-card-sender-column2">
        <div class="sender-card-sender__services-header">Booket services</div>
        <div
          v-for="(service, index) in services"
          :key="`service-${index}`"
          class="sender-card-sender__service"
        >
          {{ service.scdescription }}
        </div>
      </div>
    </div>

    <div class="sender-card-actions">
      <div class="sender-card-actions__support">
        <btn
          label="Afsenders kundeservice"
          width="100%"
          @click="$emit('service-click')"
        />
      </div>
      <div class="sender-card-actions__options">
        <btn
          v-if="canChangePlacement"
          label="Vælg placering"
          width="100%"
          @click="$emit('options-click')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/Btn";
export default {
  name: "SenderCard",
  components: {
    Btn,
  },
  props: {
    orderNumber: {
      type: String,
      default: "",
    },
    company: {
      type: String,
      default: "",
    },
    packagesNum: {
      type: String,
      default: "",
    },
    placement: {
      type: String,
    },
    services: {
      type: Array,
    },
    canChangePlacement: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.sender-card {
  margin-top: 30px;
  background-color: #fff;
  color: #1d4962;
  padding: 20px 16px;
  border-radius: 8px;
}
/* Order */
.sender-card-order {
  margin-bottom: 20px;
}
.sender-card-order__header {
  font-weight: bold;
  text-transform: uppercase;
}
.sender-card-order__number {
  font-size: 32px;
  font-weight: bold;
  color: #05bce4;
}
/* Sender */
.sender-card-sender__header {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
}
.sender-card-sender__company-header,
.sender-card-sender__packages-num-header,
.sender-card-sender__placement-header,
.sender-card-sender__services-header {
  font-weight: bold;
}
.sender-card-sender__company,
.sender-card-sender__packages-num,
.sender-card-sender__placement {
  margin-bottom: 10px;
}
/* Actions */
.sender-card-actions {
  margin-top: 30px;
}
.sender-card-actions__support {
  margin-bottom: 10px;
}

@media screen and (min-width: 1024px) {
  .sender-card-sender {
    display: flex;
    flex-wrap: wrap;
  }

  .sender-card-sender-column1 {
    width: 50%;
  }
  .sender-card-sender-column2 {
    width: 50%;
  }

  .sender-card-actions {
    display: flex;
    justify-content: space-between;
  }
}
</style>
