import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import TrackInput from "../views/TrackInput.vue";
import Track from "../views/Track.vue";
import Overview from "../views/Overview.vue";
import TrackAdvanced from "../views/TrackAdvanced.vue";
import Create from "../views/Create.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/trackinput",
    name: "TrackInput",
    component: TrackInput,
  },
  {
    path: "/t/:id",
    name: "Track",
    component: Track,
  },
  {
    path: "/overview",
    name: "Overview",
    component: Overview,
  },
  {
    path: "/trackadvanced/:id",
    name: "TrackAdvanced",
    component: TrackAdvanced,
  },
  {
    path: "/create",
    name: "Create",
    component: Create,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
