<template>
  <table class="table-select" cell-spacing="0" cell-pa>
    <tr v-if="header">
      <th class="table-select__label-header">{{ header }}</th>
      <th>Man-fre</th>
      <th>Lør</th>
      <th>Vælg</th>
    </tr>
    <tr
      :class="{
        'table-select__item': true,
        'table-select__item--disabled': item.disabled,
      }"
      v-for="(item, index) in items"
      :key="`table-select-row-${index}`"
      @click="itemClick(item)"
    >
      <td class="table-select__description-cell">{{ item.scdescription }}</td>
      <td class="table-select__day-cell">{{ item.weekdays ? "x" : "" }}</td>
      <td class="table-select__day-cell">{{ item.weekends ? "x" : "" }}</td>
      <td class="table-select__select-cell">
        <img
          v-show="current.indexOf(item) === -1"
          class="table-select__check"
          src="images/table-select-check.svg"
        />
        <img
          v-show="current.indexOf(item) > -1"
          class="table-select__check--active"
          src="images/table-select-check--active.svg"
        />
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "TableSelect",
  props: {
    header: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    current: {
      type: Array,
      default: null,
    },
  },
  methods: {
    itemClick(item) {
      if (item.disabled) {
        return;
      }
      this.$emit("toggle", item);
    },
  },
};
</script>

<style>
.table-select {
  margin-bottom: 30px;
  border: none;
  border-spacing: 0;
  width: 100%;
}
.table-select__item {
  cursor: pointer;
}
.table-select__item:last-child td {
  border-bottom: 1px solid #fff;
}
.table-select td {
  border-top: 1px solid #fff;
  padding: 10px;
}
.table-select td:first-child {
  border-right: 1px solid #fff;
}
.table-select__label-header {
  text-align: left;
}
.table-select__description-cell {
  border-left: 1px solid #fff;
}
.table-select__day-cell {
  text-align: center;
  border-right: 1px solid #fff;
  width: 80px;
}
.table-select__select-cell {
  border-right: 1px solid #fff;
  text-align: center;
  width: 80px;
}
.table-select__check {
  margin-right: 1px;
}
.table-select__item--disabled {
  cursor: default;
  color: rgba(255, 255, 255, 0.3);
}
.table-select__item--disabled img {
  opacity: 0.3;
}

@media screen and (min-width: 768px) {
  .table-select td,
  .table-select th {
    padding: 20px;
  }
}

@media (hover: hover) {
  .table-select__item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .table-select__item--disabled:hover {
    background-color: transparent;
  }
}
</style>
