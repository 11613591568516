<template>
  <div class="overview-table-search">
    <input
      v-model="term"
      type="text"
      class="overview-table-search__field"
      placeholder="Søg i oversigten"
      @keyup.enter="$emit('search', term)"
    />
    <a class="overview-table-search__button" @click="$emit('search', term)">
      <img
        class="overview-table-search__button-icon"
        src="images/overview-table-search-icon.svg"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: "OverviewTableSearch",
  data() {
    return {
      term: "",
    };
  },
};
</script>

<style>
.overview-table-search {
  position: relative;
  margin-top: 20px;
}
.overview-table-search__field {
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 8px;
  padding: 0 60px 0 14px;
}
.overview-table-search__button {
  position: absolute;
  right: 1px;
  top: 1px;
  height: 43px;
  width: 43px;
  background-color: #56bae0;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .overview-table-search {
    width: 40%;
    margin-top: 0;
    margin-left: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .overview-table-search {
    width: 430px;
  }
}
</style>
