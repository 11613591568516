<template>
  <div class="package">
    <div
      :class="{ 'package-bar': true, 'package-bar--expanded': expanded }"
      @click="expanded = !expanded"
    >
      <div class="package-bar-column1">
        <img class="package-bar__icon" :src="barIcon" />
        <div class="package-bar__header">
          Pakkenr: <strong>{{ item.packageId }}</strong
          ><span v-if="item.contents"> - {{ item.contents }}</span>
        </div>
      </div>
      <div class="package-bar-column2">
        <div
          v-if="downloadLabelEnabled"
          class="package-bar__label"
          @click.stop="$emit('download-label-click', item.packageId)"
        >
          Hent PDF-label
        </div>
        <div class="package-bar__info">
          Vægt: <strong>{{ weight }} kg.</strong> - Volume:
          <strong>{{ volume }} m3</strong>
        </div>
      </div>
      <img class="package-bar__chevron" src="images/package-bar-chevron.svg" />
    </div>
    <div v-show="expanded" class="package__content">
      <package-status-table
        :rows="item.statuses"
        @location-click="locationClick"
        @photo-click="photoClick"
        @signature-click="signatureClick"
      />
    </div>
  </div>
</template>

<script>
import PackageStatusTable from "@/components/PackageStatusTable";

export default {
  name: "PackageItem",
  components: {
    PackageStatusTable,
  },
  data() {
    return { expanded: false };
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    downloadLabelEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    barIcon() {
      return this.expanded
        ? "images/package-bar-icon--expanded.svg"
        : "images/package-bar-icon.svg";
    },
    weight() {
      return Math.round(this.item.weight * 100) / 100;
    },
    volume() {
      return Math.round(this.item.volume * 100) / 100;
    },
  },
  methods: {
    locationClick(coords) {
      this.$emit("location-click", coords);
    },
    photoClick(url) {
      this.$emit("photo-click", url);
    },
    signatureClick(url) {
      this.$emit("signature-click", url);
    },
  },
};
</script>

<style>
.package {
  margin-top: 20px;
}
.package-bar {
  position: relative;
  background-color: #fff;
  color: #004a64;
  border-radius: 8px;
  padding: 12px 40px 12px 17px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  transition: background-color 0.1s ease-out, color 0.1s ease-out;
}
.package-bar.package-bar--expanded {
  background-color: #56bae0;
  color: #fff;
}
.package__content {
  margin-bottom: 60px;
}
.package-bar-column1 {
  width: 100%;
  padding-right: 14px;
  display: flex;
  align-items: center;
}
.package-bar-column2 {
  flex-grow: 1;
  align-items: center;
}
.package-bar__icon {
  width: 30px;
  height: auto;
}
.package-bar__header {
  margin-left: 14px;
}
.package-bar__label {
  border: 1px solid #004a64;
  border-radius: 4px;
  padding: 3px 10px;
  cursor: pointer;
  margin-top: 10px;
  display: inline-block;
}
.package-bar.package-bar--expanded .package-bar__label {
  border-color: #fff;
}
.package-bar__info {
  margin-top: 10px;
  min-width: 300px;
  text-align: right;
  margin-right: 10px;
}
.package-bar__chevron {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
}
.package-bar--expanded .package-bar__chevron {
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 1024px) {
  .package-bar-column1 {
    width: initial;
  }
  .package-bar-column2 {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
  .package-bar__info {
    margin-top: 0;
  }
  .package-bar__label {
    margin-top: 0;
  }
}
@media (hover: hover) {
  .package-bar:hover {
    background-color: #d0e9f3;
  }
  .package-bar.package-bar--expanded:hover {
    background-color: #56bae0;
  }
}
</style>
