<template>
  <a class="back-btn" @click="$emit('click')">
    <img
      class="back-btn__icon"
      src="images/back-chevron-left.svg"
      alt="Tilbage til oversigt"
    />
    {{ label }}
  </a>
</template>

<script>
export default {
  name: "BackBtn",
  props: {
    label: { type: String, default: "" },
  },
};
</script>

<style>
.back-btn {
  display: block;
  cursor: pointer;
  margin-top: 6px;
  margin-bottom: 20px;
}

.back-btn__icon {
  width: 12px;
  height: auto;
  margin-bottom: -3px;
  margin-right: 10px;
}

@media screen and (min-width: 1100px) {
  .back-btn {
    position: absolute;
    top: 42px;
  }
}
</style>
