import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia, PiniaVuePlugin } from "pinia";
import Vuelidate from "vuelidate";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import VBodyScrollLock from "v-body-scroll-lock";
import VTooltip from "v-tooltip";
import "@/assets/css/tooltip.css";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(Vuelidate);
Vue.use(VBodyScrollLock);
Vue.use(VTooltip);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  pinia,
}).$mount("#app");
