import { defineStore } from "pinia";
import axios from "axios";

const trackAdvancedEndpoint = `${window["bft-settings"].apiUrl}/orders/`;

export const useTrackAdvancedStore = defineStore("trackAdvanced", {
  state: () => ({
    loaded: false,
    shipment: null,
  }),
  actions: {
    async loadData(auth, id) {
      try {
        const response = await axios.get(trackAdvancedEndpoint + id, {
          headers: { Authorization: `Basic ${auth}` },
        });
        this.loaded = true;
        this.shipment = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async saveDeliveryOption(auth, id, deliveryOption) {
      try {
        const response = await axios.put(
          trackAdvancedEndpoint + id + "/deliveryoption",
          deliveryOption,
          {
            headers: { Authorization: `Basic ${auth}` },
          }
        );
        this.shipment = response.data;
        this.loaded = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
