<template>
  <div class="track">
    <loading-indicator
      v-if="!trackStore.loaded || !deliveryOptionsStore.loaded"
    />
    <no-results
      v-if="trackStore.loaded && !trackStore.shipment"
      message="Der findes ikke noget på det søgte shipmentnummer"
    />
    <div
      class="track-content"
      v-if="
        trackStore.loaded && trackStore.shipment && deliveryOptionsStore.loaded
      "
    >
      <row background-color="#1d4962">
        <statusbar
          :items="trackStore.statusItems"
          :current="trackStore.shipment.deliveryStatus"
        />
      </row>
      <row>
        <columns>
          <column>
            <greeting-message
              :header="`Hej ${trackStore.shipment.recipient.name}`"
              :message="trackStore.shipment.currentStatusDescription"
            />
            <delivery-message
              :header="trackStore.shipment.currentStatusHeader"
              :time="trackStore.shipment.currentStatusTime"
              :date="trackStore.shipment.currentStatusDate"
              :note="deliveryNote"
              :downloadLabelsEnabled="false"
            />
          </column>
          <column>
            <sender-card
              :order-number="trackStore.shipment.orderId"
              :company="trackStore.shipment.partnerName"
              :packages-num="trackStore.shipment.packages.length.toString()"
              :can-change-placement="canChangePlacement"
              :placement="shipment.placement"
              :services="shipment.services"
              @service-click="senderServiceClick"
              @options-click="optionsClick"
            />
          </column>
        </columns>
      </row>
      <row>
        <package-list
          :packages="trackStore.shipment.packages"
          :downloadLabelEnabled="false"
        />
      </row>
    </div>
    <modal-overlay
      v-if="options.visible"
      @close="options.visible = false"
      v-body-scroll-lock="options.visible"
      center
      :close-enabled="false"
    >
      <delivery-options
        :options="deliveryOptions"
        @close-click="options.visible = false"
        @confirm-option="saveDeliveryOption"
      />
    </modal-overlay>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useTrackStore } from "../store/TrackStore";
import { useDeliveryOptionsStore } from "../store/DeliveryOptionsStore";
import LoadingIndicator from "@/components/LoadingIndicator";
import Row from "@/components/Row";
import Columns from "@/components/Columns";
import Column from "@/components/Column";
import Statusbar from "@/components/Statusbar";
import GreetingMessage from "@/components/GreetingMessage";
import DeliveryMessage from "@/components/DeliveryMessage";
import SenderCard from "@/components/SenderCard";
import PackageList from "@/components/PackageList";
import ModalOverlay from "@/components/ModalOverlay";
import DeliveryOptions from "@/components/DeliveryOptions";
import NoResults from "@/components/NoResults";

const DELIVERY_NOTE_PARTNER_NAME_BLACKLIST = ["Flowr"];

export default {
  name: "Track",
  components: {
    LoadingIndicator,
    Row,
    Column,
    Columns,
    Statusbar,
    GreetingMessage,
    DeliveryMessage,
    SenderCard,
    PackageList,
    ModalOverlay,
    DeliveryOptions,
    NoResults,
  },
  data() {
    return {
      options: {
        visible: false,
      },
    };
  },
  created() {
    this.trackStore.loaded = false;
  },
  mounted() {
    this.deliveryOptionsStore.loadData();
    this.trackStore.loadData(this.$route.params.id);
  },
  computed: {
    ...mapStores(useTrackStore, useDeliveryOptionsStore),
    deliveryOptions() {
      return this.deliveryOptionsStore.options;
    },
    shipment() {
      return this.trackStore.shipment;
    },
    canChangePlacement() {
      return (
        this.shipment.deliveryStatus < 5 && this.shipment.changeDeliveryOption
      );
    },
    deliveryNote() {
      // Set the note if status is 3 or 4 and partner name is not in the blacklist and info30min is active
      const deliveryStatusOK =
        this.shipment.deliveryStatus === 3 ||
        this.shipment.deliveryStatus === 4;
      const partnerNameOk =
        DELIVERY_NOTE_PARTNER_NAME_BLACKLIST.indexOf(
          this.shipment.partnerName
        ) === -1;
      const info30OK = this.shipment.info30min === 1;
      return deliveryStatusOK && partnerNameOk && info30OK
        ? "Ca. 30 min. før levering modtager du en SMS"
        : "";
    },
  },
  methods: {
    senderServiceClick() {
      window.open(`https://${this.trackStore.shipment.supportUrl}`);
    },
    optionsClick() {
      this.options.visible = true;
    },
    saveDeliveryOption(optionId) {
      this.options.visible = false;
      const option = this.deliveryOptions.find(
        (option) => option.id === optionId
      );
      this.trackStore.saveDeliveryOption(this.shipment.shipmentId, option);
    },
  },
};
</script>
